import React from "react";
import '../../App.css';
import Careers from "../Careers"

export default function Experience() {
    return (
        <>
            <h1 className="experience">MY CAREER</h1>
            <Careers />
        </>
    )
};